<template>
  <svg
    width="1600px"
    height="900px"
    viewBox="0 0 1600 900"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M791.400005,383.130859 L837.685547,404.128906 C837.685547,410.595052 838.352214,510.396484 839.685547,703.533203 C838.352214,710.664714 831.004623,713.735494 817.642776,712.745545 L745,711.745545 L710.697266,717.814453 L638,717.814453 C634.26667,717.814453 632.400005,719.942969 632.400005,724.200001 L632.400005,739.400002 L638,739.400002"
        id="path-1"
        class="path-line"
      />
      <filter
        x="-9.4%"
        y="-5.7%"
        width="118.8%"
        height="111.2%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feMorphology
          radius="3.00000012"
          operator="dilate"
          in="SourceAlpha"
          result="shadowSpreadOuter1"
        />
        <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feMorphology radius="3.00000012" operator="erode" in="SourceAlpha" result="shadowInner" />
        <feOffset dx="0" dy="0" in="shadowInner" result="shadowInner" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="shadowInner"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="50%"
        gradientTransform="translate(0.500000,0.500000),rotate(90.000000),scale(1.000000,0.980555),translate(-0.500000,-0.500000)"
        id="radialGradient-3"
      >
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.466182255" offset="100%" />
      </radialGradient>
      <path
        d="M14.399939,0 C9.09791629,0 4.80000019,4.20140023 4.80000019,9.38394479 C4.80000019,11.6930172 7.66412882,16.5600732 7.66412882,16.5600732 L14.399939,27.6000011 L20.7486549,16.5600732 C20.7486549,16.5600732 24.000001,11.6584597 24.000001,9.38394479 C24.000001,4.20140023 19.7019618,0 14.399939,0"
        id="path-4"
      />
      <filter
        x="-101.6%"
        y="-70.7%"
        width="303.1%"
        height="248.3%"
        filterUnits="objectBoundingBox"
        id="filter-5"
      >
        <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1" />
        <feOffset dx="0" dy="0" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="5.5" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0"
          type="matrix"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g id="Casinos" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Aria">
        <g id="path-aria">
          <g id="Route" stroke-linecap="round" stroke-linejoin="round">
            <!-- <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1" /> -->
            <use stroke="#FFFFFF" stroke-width="6.00000024" xlink:href="#path-1" />
          </g>
          <g id="Ending-Point" transform="translate(622.800004, 724.000000)">
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="14.6000011"
              r="14.4000006"
            />
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="14.6000011"
              r="9.60000038"
            />
            <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="14.6000011" r="4.80000019" />
          </g>
          <image id="Bitmap" x="496" y="673" width="157" height="141" href="@/assets/images/icons/end.png"></image>
          <g id="Starting-Point" transform="translate(777.800004, 356.000000)">
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="27.6000011"
              r="14.4000006"
            />
            <circle
              id="Oval-back"
              fill="url(#radialGradient-3)"
              cx="14.4000006"
              cy="27.6000011"
              r="9.60000038"
            />
            <circle id="Oval" fill="#5A5A5A" cx="14.4000006" cy="27.6000011" r="4.80000019" />
            <g id="Fill-1">
              <use fill="black" fill-opacity="1" filter="url(#filter-5)" xlink:href="#path-4" />
              <use fill="#FFFFFF" fill-rule="evenodd" xlink:href="#path-4" />
            </g>
          </g>
          <text
            id="Aria"
            font-family="ArtegraSans-Regular, Artegra Sans"
            font-size="14.4000006"
            font-weight="normal"
            line-spacing="15.6000006"
            fill="#FFFFFF"
          >
            <tspan x="820" y="388">Aria</tspan>
          </text>
          <rect id="Rectangle" fill="#000000" x="1599" y="899" width="1" height="1" />
          <rect id="Rectangle" fill="#000000" x="0" y="0" width="1" height="1" />
          <rect id="Rectangle" fill="#000000" x="1599" y="0" width="1" height="1" />
          <rect id="Rectangle" fill="#000000" x="0" y="899" width="1" height="1" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "AriaPath",
  mounted() {
    var path = document.querySelector(".path-line");
    var length = path.getTotalLength();
    path.style.transition = path.style.WebkitTransition = "none";
    path.style.strokeDasharray = length + " " + length;
    path.style.strokeDashoffset = length;
    path.getBoundingClientRect();
    path.style.transition = path.style.WebkitTransition =
      "stroke-dashoffset 2s ease-in-out";
    path.style.strokeDashoffset = "0";

    // var pathGlow = document.querySelector(".path-glow");
    // var lengthGlow = pathGlow.getTotalLength();
    // pathGlow.style.transition = pathGlow.style.WebkitTransition = "none";
    // pathGlow.style.strokeDasharray = lengthGlow + " " + lengthGlow;
    // pathGlow.style.strokeDashoffset = lengthGlow;
    // pathGlow.getBoundingClientRect();
    // pathGlow.style.transition = pathGlow.style.WebkitTransition =
    //   "stroke-dashoffset 2s ease-in-out";
    // pathGlow.style.strokeDashoffset = "0";
  }
};
</script>

<style lang="scss" scoped>
</style>